<template>
  <div class="home">
    <h1>Welcome, {{username}}</h1>
    <button @click="logout">Logout</button>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  computed: {
    username() {
      return this.$store.state.user?.alias;
    }
  },
  methods: {
    logout() {
      this.$https.delete('/sessions')
          .then(() => {
            this.$store.dispatch('logoutUser');
            this.$router.push({name: 'login'});
          })
          .catch(error => {
            console.error('Logout failed:', error);
          });
    }
  }
}
</script>

<style scoped>
/* Add your styles here */
</style>
