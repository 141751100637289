import { createStore } from 'vuex';

const store = createStore({
    state() {
        return {
            user: JSON.parse(localStorage.getItem('user')) || null,
        };
    },
    mutations: {
        SET_USER_DATA(state, userData) {
            state.user = userData;
            localStorage.setItem('user', JSON.stringify(userData));
        },
        CLEAR_USER_DATA(state) {
            state.user = null;
            localStorage.removeItem('user');
        },
    },
    actions: {
        fetchUserData({ commit }, userData) {
            commit('SET_USER_DATA', userData);
        },
        logoutUser({ commit }) {
            commit('CLEAR_USER_DATA');
        }
    },
    getters: {

    }
});

export default store;