import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://api.codeweb.ee',
});

const install = (app) => {
  app.config.globalProperties.$https = axiosInstance;
};

export { axiosInstance };
export default install;