import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';

import store from '../store';
import { axiosInstance } from '../plugins/axios';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue'),
    meta: {
      requiresAuth: false,
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {
  let isAuthenticated = false;

  try {
    const response = await axiosInstance.get('/sessions');
    if (response.status === 202 && response.data && response.data.user) {
      await store.dispatch('fetchUserData', response.data.user);
      isAuthenticated = true;
    }
  } catch (error) {
    console.log(error.message);
  }

  // add further routes here too possibly
  if ((to.path === '/login') && isAuthenticated) {
    next({ name: 'home' });
  } else if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
    next({ name: 'login' });
  } else {
    next();
  }
});

export default router;